import { useState, useEffect, useRef } from "react"

import * as THREE from 'three';

const findLastParents = (data) => {
    let lastParentsCurr = [];
    if (data.children) {
        let canGoDeeper = false;
        for (let i=0; i<data.children.length; i++) {
            const child = data.children[i]
            const {lastNode, lastParents} = findLastParents(child);
            canGoDeeper = !lastNode || canGoDeeper
            lastParentsCurr.push(...lastParents)
        }
        if (!canGoDeeper) {
            lastParentsCurr.push(data);
        }
        return {lastNode: false, lastParents: lastParentsCurr}
    }
    else return {lastNode: true, lastParents: lastParentsCurr}
}

function generateDate() {
    const data = {}
    const data_template = [3]
    const genData = (node, idx) => {
        const children = data_template[idx] || 0;
        if (children > 0) {
            node.children = []
            for (let i=0; i<children; i++) {
                const newChild = {}
                node.children.push(newChild)
                genData(newChild, idx+1)
            }
        }
    }

    genData(data, 0)
    return data;
}

const maxColDepth = 6;
function DrawCone(node, scene, cones, depth) {
    const span = node.max - node.min;
    if (node.children) console.log(node)

    const coldepth = parseInt(((depth+1)/maxColDepth)*100)
    const color_a = `hsl(50, ${coldepth}%, ${coldepth}%)`

    const geometry = new THREE.CircleGeometry( node.radius, 32, 0, span ); 

    const material = new THREE.MeshBasicMaterial( { color: color_a } ); 
    const circle = new THREE.Mesh( geometry, material ); 
    circle.rotateZ(node.min)
    circle.position.x = node.node.x;
    circle.position.y = node.node.y;
    cones.push(circle)
    scene.add( circle )
}

function calculateCones(added, scene, {cones}, depth=0) {
if (added.children) {
    for (let i=0; i<added.children.length; i++) {
        const node = added.children[i]
        DrawCone(node, scene, cones, depth)
        calculateCones(node, scene, {cones}, depth+1)
    }
}
}

function calculateAngleSum(angleA, distanceA, angleB, distanceB, scene, elements) {

    // while (angleA < -Math.PI) angleA += 2 * Math.PI;
    // while (angleA > Math.PI) angleA -= 2 * Math.PI;

    // while (angleB < -Math.PI) angleB += 2 * Math.PI;
    // while (angleB > Math.PI) angleB -= 2 * Math.PI;
    // Calculate horizontal and vertical components of vector AB
    const AB_x = distanceA * Math.cos(angleA);
    const AB_y = distanceA * Math.sin(angleA);
    
    // Calculate horizontal and vertical components of vector BC
    const BC_x = distanceB * Math.cos(angleB);
    const BC_y = distanceB * Math.sin(angleB);
    
    // Add components to find components of vector AC
    const AC_x = AB_x + BC_x;
    const AC_y = AB_y + BC_y;

    const geometry = new THREE.CircleGeometry( 0.2, 32 ); 

    const material = new THREE.MeshBasicMaterial( { color: 'white' } ); 
    const circle = new THREE.Mesh( geometry, material );
    circle.position.x = AC_x;
    circle.position.y = AC_y;
    elements.points.push(circle)
    scene.add( circle )
    
    // Calculate distance between point A and point C
    const distance = Math.sqrt(AC_x**2 + AC_y**2);
    
    // Calculate angle between vector AC and positive x-axis
    const angle = Math.atan2(AC_y, AC_x);

    // while (angle < -Math.PI) angle += 2 * Math.PI;
    // while (angle > Math.PI) angle -= 2 * Math.PI;
    
    return { distance, angle };
}

function calculateAngleSum_x(angleAB, distanceAB, angleBC, distanceBC) {

    let angleDiff = angleAB - angleBC;
    while (angleDiff > Math.PI) angleDiff -= 2 * Math.PI;
    while (angleDiff < -Math.PI) angleDiff += 2 * Math.PI;
    // Calculate the length of side AC using the law of cosines
    const distanceACSquared = distanceAB**2 + distanceBC**2 - 2 * distanceAB * distanceBC * Math.cos(angleDiff);
    const distance = Math.sqrt(distanceACSquared);
    
    // Calculate the sine of the angle between the line segments AC and AB using the law of sines
    const sinAngleAC = Math.sin(angleBC) * (distance / distanceBC);
    
    // Calculate the angle between the line segments AC and AB using arcsine
    const angle = Math.asin(sinAngleAC);
    
    return { distance, angle };
}

const span = 180 * Math.PI/180;

function genPositions(node, scene, elements) {
    const added = {x: node.x, y: node.y, node}
    genPositionsRecursive(node, added, added, 0, scene, elements)
    return added;
}
function genPositionsRecursive(node, addedNode, addedRoot, depth=0, scene, elements) {
    const colors = ['orange', 'green', 'red', 'pink', 'purple', 'white', 'yellow', 'blue']
    if (node.children) {
        addedNode.children = []
        const radius = 1;
        const slice = depth === 0 ? 2 * Math.PI / node.children.length : span / (node.children.length  + 1)
        let hasGrandchildren = false;
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i]
            const angle = depth === 0 ? slice * i : (node.angle) + slice*(i+1) - (span/2)
            child.angle = angle;
            child.x = node.x + (radius * Math.cos(angle));
            child.y = node.y + (radius * Math.sin(angle));
            child.color = colors[depth]

            const addedChild = {x: child.x, y: child.y, node: child, radius}
            addedNode.children.push(addedChild)

            const result = genPositionsRecursive(child, addedChild, addedRoot, depth+1, scene, elements)

            hasGrandchildren = result.hasChildren || hasGrandchildren
        }
        addedNode.isLastParent = !hasGrandchildren
        if (addedNode.isLastParent) {
            addedNode.min = node.children[0].angle;
            addedNode.max = node.children.slice(-1)[0].angle
            addedNode.radius = radius
        }
        else {
            const childResults = addedNode.children.reduce((p, c) => {
                let min, max, radius;
                if (c.min !== undefined) {
                    const rMin  = calculateAngleSum(c.node.angle, 1, c.min, c.radius, scene, elements)
                    const rMax  = calculateAngleSum(c.node.angle, 1, c.max, c.radius, scene, elements)
                    min = Math.min(rMin.angle, p.min)
                    max = Math.max(rMax.angle, p.max)
                    radius = Math.max(rMin.distance, rMax.distance, p.radius)
                }
                else {
                    min = Math.min(p.min, c.node.angle)
                    max = Math.max(p.max, c.node.angle)
                    radius = Math.max(p.radius, c.radius)
                }
                return {min, max, radius}
            }, {min: Infinity, max: -Infinity, radius: -10000})
            addedNode.min = childResults.min
            addedNode.max = childResults.max
            addedNode.radius = childResults.radius
        }
        return {hasChildren: true};
    }
    else return {hasChildren: false};
}

//Set to true if
//this child has no children and NONE of the other children have children

function emptyScene(scene, {circles, lines, cones, points}) {
    circles.forEach((c) => scene.remove(c))
    lines.forEach((l) => scene.remove(l))
    cones.forEach((c) => scene.remove(c))
    points.forEach((c) => scene.remove(c))
}


function drawChildrenRecursive(scene, node, depth=0, {circles, lines}) {
    if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i]
            const geometry = new THREE.CircleGeometry( 0.1, 32);
            const material = new THREE.MeshBasicMaterial( { color: child.color } );
            const circle = new THREE.Mesh( geometry, material );
            circle.position.x = child.x;
            circle.position.y = child.y;
            circle.node = child;
            scene.add( circle );
            circles.push(circle)

            const linematerial = new THREE.LineBasicMaterial({
                color: child.color
            });
            
            const points = [];
            points.push( new THREE.Vector3( node.x, node.y, 0 ) );
            points.push( new THREE.Vector3( child.x, child.y, 0 ) );
            const linegeometry = new THREE.BufferGeometry().setFromPoints( points );
            const line = new THREE.Line( linegeometry, linematerial );
            scene.add( line );
            lines.push(line)

            drawChildrenRecursive(scene, child, depth+1, {circles, lines})
        }
    }
}

function drawCentralNode(scene, node,{circles}) {
    const geometry = new THREE.CircleGeometry( 0.1, 32);
    const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    const circle = new THREE.Mesh( geometry, material );
    circle.node = node;
    scene.add( circle );
    circles.push(circle)
}


const Tester = () => {
    const mainRef = useRef();
    const [lastNodeAdded, setLastNodeAdded] = useState()

    useEffect(() => {
        if (mainRef.current) {
            while(mainRef.current.firstChild)
            mainRef.current.removeChild(mainRef.current.firstChild);
        }

        const elements = {
            circles: [],
            lines: [],
            cones: [],
            points: []
        }

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

        setCameram(camera)

        const data = generateDate();
        data.x = 0;
        data.y = 0;
        genPositions(data, scene, elements);

        drawCentralNode(scene, data, elements)

        drawChildrenRecursive(scene, data, 0, elements)

        camera.position.z = 5;
        
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize( window.innerWidth, window.innerHeight );
        mainRef.current.appendChild( renderer.domElement );

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        renderer.domElement.addEventListener( 'mousedown', function( event ) {
    
            const rect = renderer.domElement.getBoundingClientRect();
            mouse.x = ( ( event.clientX - rect.left ) / ( rect.width - rect.left ) ) * 2 - 1;
            mouse.y = - ( ( event.clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;
             
            raycaster.setFromCamera( mouse, camera );
            const intersects = raycaster.intersectObjects( elements.circles );
            if ( intersects.length > 0 ) {
                const obj = intersects[intersects.length -1]
                const node = obj.object.node
                const newNode = {}
                if (node.children) node.children.push(newNode)
                else node.children = [newNode]
                
                emptyScene(scene, elements);
                const added = genPositions(data, scene, elements)
                drawCentralNode(scene, data, elements)
                calculateCones(added, scene, elements)
                drawChildrenRecursive(scene, data, 0, elements)
            }

        }, false );



        function animate() {
            requestAnimationFrame( animate );

            
        if (camera.scrollX || camera.scrollY) {
            camera.position.set(camera.position.x + camera.scrollX, camera.position.y + camera.scrollY, 5)
            camera.scrollX = 0;
            camera.scrollY = 0;
        }


            renderer.render( scene, camera );
        }
        animate();
    }, [])

    const [cameram, setCameram] = useState()

    const scrollFunc =  (e) => {
        e.preventDefault();
        if (!cameram.scrollX) cameram.scrollX = +e.deltaX/10
        else cameram.scrollX += e.deltaX/10
  
        if (!cameram.scrollY) cameram.scrollY = -e.deltaY/10
        else cameram.scrollY -= e.deltaY/10
      }

      useEffect(() => {
        if (cameram) {
          mainRef.current.removeEventListener('mousewheel', scrollFunc)
          mainRef.current.addEventListener('mousewheel', scrollFunc)
          
        }
      }, [cameram])



    return (
    <div ref={mainRef} />
    )
}

export default Tester