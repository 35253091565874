import {Link} from 'react-router-dom'

function Home() {
    return (
        <div>
            <span>Home</span>
        </div>
    )
}

export default Home