import { useState, useEffect, useCallback, useRef, useContext, useLayoutEffect, createContext } from "react"
import { useParams } from "react-router-dom"


import Background from "../../Images/texture.jpg"

import { WebsocketsContext } from "../../WebsocketsProvider"

import { LoginContext} from "../../LoginProvider"

import SharePopup from '../../Popups/SharePopup'

import DocumentHeader from "./DocumentHeader"
import DocumentKeys from "./DocumentKeys"
import DocumentRender from "./DocumentRender"
import DocumentSocket from "./DocumentSocket"

import Mindmap from "../Mindmap/MindMap"

const Document = () => {
    const {username, setDocumentId, connected} = useContext(LoginContext);
    const {documentId} = useParams()

    useEffect(() => {
        setDocumentId(documentId)
    }, [])

    const {socket} = useContext(WebsocketsContext);
    const [nodeStyle, setNodeStyle] = useState({
        indent: 150,
        minWidth: 50,
        minHeight: 18,
        marginCollapsed: 5,
        marginExpanded: 20,
        padding: 5,
        maxWidth: 250
    })

    const documentContext = createContext(null)
    const [data, setData] = useState([])

    const [grid, setGrid] = useState([])
    const [gridWindow, setGridWindow] = useState([])
    const [screenPosition, setScreenPosition] = useState({x: 0, y: 0})
    const [screenDimensions, setScreenDimensions] = useState({width: 1800, height: 800})

    const [totalDimensions, setTotalDimensions] = useState({width: 0, height: 0})

    const [cursor, setCursor] = useState(data[0])
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState("")

    const mainRef = useRef();
    const focusRef = useRef();


    const [gridWindowRefresh, setGridWindowRefresh] = useState(false)

    const [browseModifier, setBrowseModifier] = useState(false);
    const [screenPosBeforeBrowseModifierChange, setScreenPosBeforeBrowseModifierChange] = useState(null)

    const [hoveredNode, setHoveredNode] = useState(null);

    const [isNewNode, setIsNewNode] = useState(false);

    const [showSharePopup, setShowSharePopup] = useState(false);

    const [temporaryIdCounter, setTemporaryIdCounter] = useState(0);

    const [waitingUpdateQueue, setWaitingUpdateQueue] = useState([])
    const [updatePending, setUpdatePending] = useState({})

    const [windowSize, setWindowSize] = useState({width: 0, height: 0});
    const [editContainerDimensions, setEditContainerDimensions] = useState({width: 0, height: 0})

    const [mindMapMode, setMindMapMode] = useState(false)
    


    var getDimensionsOfText = function(text, styles) {
        var isObjectJSON = function(obj) {
            return obj && typeof obj === 'object' && !Array.isArray(obj);
        };
    
        var element = document.createElement('div');
        if (isObjectJSON(styles)) {
            var styleKeys = Object.keys(styles);
            for (var i = 0, n = styleKeys.length; i < n; ++i) {
                element.style[styleKeys[i]] = styles[styleKeys[i]];
            }
        }
        element.style.display = 'inline-block';
        element.innerHTML = text;
        document.body.appendChild(element);
        var width = element.offsetWidth;
        var height = element.offsetHeight;
        document.body.removeChild(element);
        return {width, height};
    };

    const getDimensionsOfNode = (node) => {
        const {width, height} = getDimensionsOfText(node.text, {minWidth: nodeStyle.minWidth + 'px', minHeight: nodeStyle.minHeight + 'px', padding: nodeStyle.padding + "px", maxWidth: nodeStyle.maxWidth + "px"})
        return {width: width+5, height}
    }





    const addToWaitingUpdateQueue = (d) => {
        setWaitingUpdateQueue((prev) => [...prev, d])
    }

    const scheduleUpdate = (temporaryId, d) => {
        if (updatePending[temporaryId]) updatePending[temporaryId].push(d)
        else updatePending[temporaryId] = [d]
    }

    const calculatePath = (node) => {
        const calculatePathSegment = (node, arr) => {
            arr.push(node.id)
            if (node.parent) calculatePathSegment(node.parent, arr)
        }

        const arr = [];
        calculatePathSegment(node, arr)
        return arr.reverse();
    }

    const getTemporaryId = () => {
        const id = temporaryIdCounter;
        setTemporaryIdCounter((prev) => prev+1)
        return id;
    }

    const createSiblingNode = (node, before, key) => {
        const idx = node.parent.children.indexOf(node);
        const temporaryId = getTemporaryId()
        node.parent.children = [...node.parent.children.slice(0, idx + (!before ? 1 : 0)), {text: '', width: nodeStyle.minWidth, height: nodeStyle.minHeight, temporaryId, creator: username}, ...node.parent.children.slice(idx + (!before ? 1 : 0), node.parent.children.length)]
        setData([...data])
        setCursor(node.parent.children[idx + (!before ? 1 : 0)])
        setEditText(key)
        setIsEditing(true);
        setGridWindowRefresh(true);
        addToWaitingUpdateQueue(node.parent.children[idx + (!before ? 1 : 0)])
        if (node.id)
            socket.emit("newSiblingNode", {path: calculatePath(node), before, temporaryId})
        else
            scheduleUpdate(node.temporaryId, {node, before, type: 'newSiblingNode', temporaryId})
    }

    const createChildNode = (node, key) => {
        const temporaryId = getTemporaryId()
        const newNode = {text: '', width: nodeStyle.minWidth, height: nodeStyle.minHeight, temporaryId, creator: username}
        if (node.children)
            node.children = [newNode, ...node.children]
        else
            node.children = [newNode]
        setData([...data])
        setCursor(newNode)
        addToWaitingUpdateQueue(newNode)
        setEditText(key)
        setIsEditing(true);
        setGridWindowRefresh(true);
        if (node.id)
            socket.emit("newChildNode", {path: calculatePath(node), temporaryId})
        else
            scheduleUpdate(node.temporaryId, {node, type: 'newChildNode', temporaryId})
    }

    const updateNodeText = (node, text) => {
        node.text = text;
        const {width, height}= getDimensionsOfNode(node)
        node.width = width;
        node.height = height;
        node.creator = username
        setIsEditing(false);
        setIsNewNode(false);
        if ((!text || text.length === 0) && (!node.children || node.children.length === 0)) {
            setCursor(
                node.prevNode ? node.prevNode :
                node.nextNode ? node.nextNode :
                node.parent
            )
            if (node.id) {
                socket.emit("deleteEmptyNode", {path: calculatePath(node)})
            }
            else
                scheduleUpdate(node.temporaryId, {node, type: 'deleteEmptyNode', path: calculatePath(node)})

            node.parent.children = node.parent.children.filter((n) => n !== node)
        }
        else {
            if (node.id)
            socket.emit("updateNodeText", {path: calculatePath(node), text})
        else
            scheduleUpdate(node.temporaryId, {node, type: 'updateNodeText', text})
        }
        setData([...data])

    }

    const editCell = (node, text) => {
        setIsEditing(true);
        setEditText(text);

        if (node.id)
            socket.emit("lockCell", {path: calculatePath(node)})
        else
            scheduleUpdate(node.temporaryId, {node, type: 'lockCell'})
    }

    const deleteCell = (node) => {
        // const parent = node.parent;
        // const idx = parent.children.indexOf(node);
        // const children = node.children;
        // if (children) {
        //     parent.children = [...parent.children.slice(0, idx), ...children, ...parent.children.slice(idx+1, parent.children.length)]
        //     setCursor(children[0])
        // }
        // else {
        //     if (parent.children.length > 2)
        //         parent.children = [...parent.children.slice(0, idx), ...parent.children.slice(idx+1, parent.children.length)]
        //     else
        //         parent.children = null

        //     setCursor(parent);
        // }  
        // setData([...data])

        // const childrenWithoutIds = children ? children.filter((c) => !c.id) : []

        // if (node.id && childrenWithoutIds.length === 0)
        //     socket.emit("deleteCell", {path: calculatePath(node), parent: parent.id})
        // else {
        //     if (!node.id) {
        //         scheduleUpdate(node.temporaryId, {node, parent, children, type: 'deleteCell'})
        //     }
        //     if (childrenWithoutIds.length > 0) childrenWithoutIds.forEach((c) => {
        //         scheduleUpdate(c.temporaryId, {node, parent, children, type: 'deleteCell'})
        //     })
        // }
    }




    useEffect(() => {
        const {width, height} = getDimensionsOfText(editText, {minWidth: nodeStyle.minWidth + 'px', minHeight: nodeStyle.minHeight + 'px', fontSize: '11pt', fontFamily: 'Arial, Helvetica, sans-serif'})
        setEditContainerDimensions({width: width+15, height})
    }, [editText])


    useLayoutEffect(() => {
        function updateSize() {
        setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const width = windowSize.width - 20;
        const height = windowSize.height - 20;
        setScreenDimensions({width, height})
    }, [windowSize])

  

    

    useEffect(() => {
        if (screenPosBeforeBrowseModifierChange && !gridWindowRefresh) {
            mainRef.current.scrollNodeIntoPosition(cursor, screenPosBeforeBrowseModifierChange)
            setScreenPosBeforeBrowseModifierChange(null)
        }
    }, [screenPosBeforeBrowseModifierChange, gridWindowRefresh])
    
    useEffect(() => {
        if (!mindMapMode && connected) {
            if (isEditing && !gridWindowRefresh) {
                mainRef.current.scrollNodeIntoView(cursor);
                setTimeout(() => mainRef.current.focusEdit(),100)
              } else {
                  if (cursor) mainRef.current.scrollNodeIntoView(cursor);
                focusRef.current.focus();
              }
        }
      }, [isEditing, gridWindowRefresh]);

      useEffect(() => {
        setHoveredNode(null)
      }, [data])

      useEffect(() => {
        if (cursor && !mindMapMode) {
            mainRef.current.scrollNodeIntoView(cursor)
        }
      }, [cursor])

    

     useEffect(() => {
        if (!browseModifier) {
            if (cursor && cursor.type === 'placeholder')
                setCursor(cursor.before ? cursor.nextNode : cursor.prevNode)
            else if (cursor && cursor.type === 'childPlaceholder') setCursor(cursor.parent);
        }
     }, [browseModifier])


     const docCtxt = {
        data,
        setData,
        setWaitingUpdateQueue,
        waitingUpdateQueue,
        setUpdatePending,
        updatePending,
        calculatePath,
        setCursor,
        cursor,
        documentId,
        nodeStyle,
        getDimensionsOfNode,
        setScreenPosBeforeBrowseModifierChange,
        isEditing,
        editText,
        browseModifier,
        setGridWindowRefresh,
        setBrowseModifier,
        deleteCell,
        updateNodeText,
        isNewNode,
        setIsNewNode,
        createSiblingNode,
        createChildNode,
        editCell,
        setIsEditing,
        setCursor,
        grid,
        setGrid,
        screenPosition,
        setGridWindow,
        gridWindow,
        setScreenPosition,
        setTotalDimensions,
        setHoveredNode,
        hoveredNode,
        updateNodeText,
        setEditText,
        screenDimensions,
        username,
        editContainerDimensions,
        totalDimensions
     }
    
    return (
        <div>
            {/* <DocumentHeader setShowSharePopup={setShowSharePopup} /> */}
            <SharePopup show={showSharePopup} onHide={() => setShowSharePopup(false)}/>
            <DocumentSocket 
            data={data}
            setData={setData}
            setWaitingUpdateQueue={setWaitingUpdateQueue}
            waitingUpdateQueue={waitingUpdateQueue}
            setUpdatePending={setUpdatePending}
            updatePending={updatePending}
            calculatePath={calculatePath}
            setCursor={setCursor}
            cursor={cursor}
            documentId={documentId} 
            nodeStyle={nodeStyle}
            getDimensionsOfNode={getDimensionsOfNode}
            />
            <div className="documentContainer">
            <img src={Background} width={screenDimensions.width} height={screenDimensions.height} style={{position: 'absolute'}}/>
            <div className="documentContent">
                {!connected && 
                <div className="disconnect-error">DISCONNECTED!</div>
                    }
                <DocumentKeys
                cursor={cursor} isEditing={isEditing} editText={editText} data={data} browseModifier={browseModifier}
                setScreenPosBeforeBrowseModifierChange={setScreenPosBeforeBrowseModifierChange}
                getNodeScreenPosition={mainRef.current?.getNodeScreenPosition}
                setGridWindowRefresh={setGridWindowRefresh}
                setBrowseModifier={setBrowseModifier}
                deleteCell={deleteCell}
                updateNodeText={updateNodeText}
                isNewNode={isNewNode}
                setIsNewNode={setIsNewNode}
                createSiblingNode={createSiblingNode}
                createChildNode={createChildNode}
                editCell={editCell}
                setIsEditing={setIsEditing}
                setCursor={setCursor}
                setMindMapMode={setMindMapMode}
                ref={focusRef}
                >
                    {mindMapMode ?
                    <Mindmap 
                    data={data}
                    cursor={cursor}
                    />
                    :
                    
                    
                    <DocumentRender 
                    data={data}
                    setGrid={setGrid}
                    grid={grid}
                    screenPosition={screenPosition}
                    browseModifier={browseModifier}
                    setGridWindow={setGridWindow}
                    gridWindow={gridWindow} 
                    setGridWindowRefresh={setGridWindowRefresh}
                    setScreenPosition={setScreenPosition}
                    setTotalDimensions={setTotalDimensions}
                    setHoveredNode={setHoveredNode}
                    hoveredNode={hoveredNode}
                    createSiblingNode={createSiblingNode}
                    createChildNode={createChildNode}
                    updateNodeText={updateNodeText}
                    setCursor={setCursor}
                    cursor={cursor}
                    isEditing={isEditing}
                    setEditText={setEditText}
                    editText={editText}
                    nodeStyle={nodeStyle}
                    screenDimensions={screenDimensions}
                    username={username}
                    editContainerDimensions={editContainerDimensions}
                    totalDimensions={totalDimensions}
                    ref={mainRef}
                    />
                    }
                </DocumentKeys>

            </div>
            </div>
        </div>
       
    )
}

export default Document