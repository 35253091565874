import * as THREE from 'three';
import renderToCanvas, {renderToCanvas_draw} from './renderToCanvas';

export default async function renderToSprite(content, nodeText, { width, height }) {
  // const canvas = await renderToCanvas(content, {
  //   width,
  //   height
  // });
    const canvas = renderToCanvas_draw(nodeText, {
    width,
    height
  });
  const map = new THREE.CanvasTexture(canvas);
  const material = new THREE.SpriteMaterial({ map });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(width / 6, height / 6);
  return sprite;
}
