import { useEffect, forwardRef, useRef, useState, useImperativeHandle, Fragment} from "react";
import { Plus } from "@styled-icons/fa-solid"

const DocumentRender = forwardRef(({data, setGrid, grid, screenPosition, browseModifier, setGridWindow, gridWindow, setGridWindowRefresh, setScreenPosition, setTotalDimensions, 
                        setHoveredNode, hoveredNode, createSiblingNode, createChildNode, updateNodeText, setCursor, cursor, isEditing, setEditText, 
                        editText, nodeStyle, screenDimensions, username, editContainerDimensions, totalDimensions}, ref) => {

    const editRef = useRef();
    const documentRef = useRef()

    const getNodeScreenPosition = (node) => {
        const left = node.xPx;
        const top = node.yPx;

        const screenLeft = documentRef.current.scrollLeft
        const screenTop = documentRef.current.scrollTop;

        return {x: left - screenLeft, y: top - screenTop}
    }

    useImperativeHandle(ref, () => {
        return {
          focusEdit() {
            editRef.current?.focus?.()
          },
          scrollNodeIntoView (node) {
            const left = node.xPx;
            const right = node.xPx + node.width
            const top = node.yPx;
            const bottom = node.yPx + node.height + 22;
    
            const screenLeft = documentRef.current.scrollLeft
            const screenTop = documentRef.current.scrollTop;
            const screenRight = screenLeft + screenDimensions.width;
            const screenBottom = screenTop + screenDimensions.height;
    
    
            let dx = 0, dy = 0;
            if (left < screenLeft) dx = left - screenLeft
            else if (right > screenRight) dx = right - screenRight
            else dx = 0;
    
            if (top < screenTop) dy = top - screenTop
            else if (bottom > screenBottom) dy = bottom - screenBottom
            else dy = 0
    
            documentRef.current.scrollTo(screenLeft + dx, screenTop + dy)
        },
        scrollNodeIntoPosition (node, position) {
            const newPos = getNodeScreenPosition(node);
            const dx = newPos.x - position.x
            const dy = newPos.y - position.y;
    
            const screenLeft = documentRef.current.scrollLeft
            const screenTop = documentRef.current.scrollTop;
    
            documentRef.current.scrollTo(screenLeft + dx, screenTop + dy)
        },
        getNodeScreenPosition
        };
      }, [screenDimensions]);


    useEffect(() => {
        let y = 0;
        let arr = []
        let totalHeight = 0;
        let maxDepth = 0;
        let maxX = 0;

        const topMargin = browseModifier ? nodeStyle.marginExpanded : nodeStyle.marginCollapsed;

        const getNode = (d, xDepth, parent) => {
            let prevChildren = false;
            maxDepth = Math.max(xDepth-1, maxDepth)
            let prevNode = undefined;
            d.forEach((node, i) => {
                node.y = y;
                node.x = xDepth-1
                node.yPx = totalHeight + topMargin;
                node.xPx = parent && !parent.root ? parent.xPx + parent.width : 0;
                maxX = Math.max(node.xPx + node.width, maxX)
                if (prevNode) {node.firstNode = false; node.prevNode = prevNode;}
                else node.firstNode = true
                prevNode = node;
                if (parent) {
                    node.parent = parent
                    }
                if (d[i+1]) {node.lastNode = false; node.nextNode = d[i+1]}
                else node.lastNode = true;
                node.prevPlaceholder = {
                    type: 'placeholder',
                    before: true,
                    nextNode: node,
                    prevNode: node.prevNode,
                    parent: node.parent,
                    children: node.children,
                    yPx: totalHeight,
                    xPx: node.xPx
                }
                node.nextPlaceholder = {
                    type: 'placeholder',
                    before: false,
                    prevNode: node,
                    nextNode: node.nextNode,
                    parent: node.parent,
                    children: node.children,
                    yPx: totalHeight + node.height+ (browseModifier ? nodeStyle.marginExpanded : nodeStyle.marginCollapsed),
                    xPx: node.xPx
                }
                if (!node.children) {
                    node.childPlaceholder = {
                        type: 'childPlaceholder',
                        parent: node,
                        prevNode: node.prevNode,
                        nextNode: node.nextNode,
                        yPx: totalHeight + nodeStyle.marginExpanded,
                        xPx: node.xPx + node.width
                    }
                }
                if (!node.root) {
                    arr.push(node)
                    const nextMargin = browseModifier ? nodeStyle.marginExpanded : nodeStyle.marginCollapsed;

                    totalHeight += node.height + nextMargin;
                }
                y++;
                prevChildren = false;
                if (node.children) {
                    getNode(node.children, xDepth+1, node)
                    prevChildren = true;
                }
            })
        }
        getNode(data, 0);
        setGrid(arr);
        setTotalDimensions({width:maxX, height: totalHeight})
    }, [data, browseModifier])

    useEffect(() => {
        const arr = []
        grid.forEach((row, y) => {
            const inYbounds = row.yPx + row.height + nodeStyle.marginCollapsed >= screenPosition.y && row.yPx + nodeStyle.marginCollapsed <= (screenPosition.y + screenDimensions.height)
            const inXbounds = row.xPx + row.width >= screenPosition.x && row.xPx  <= screenPosition.x + screenDimensions.width
            if ((inYbounds && inXbounds) || row === cursor) { 
                 arr.push(row)
            }
        })
        setGridWindow(arr);
        setGridWindowRefresh(false)
    }, [screenPosition, grid])

    useEffect(() => {
        if (isEditing) {
            ref.current.focusEdit()
        }
    }, [gridWindow])



    return (
        <div className="documentContent inner" 
                onScroll={(e) => {setScreenPosition({x: e.currentTarget.scrollLeft, y: e.currentTarget.scrollTop})}} 
                style={{width: screenDimensions.width, height: screenDimensions.height}}
                ref={documentRef}
                >
                    <>
                    {gridWindow.map((row, y) => {
                        if (!row) return null
                        return (
                            <Fragment key={y}>
                            {
                                (!isEditing || cursor !== row) ?
                                                
                                <div 
                                onMouseEnter={() => setHoveredNode(row)} 
                                onMouseLeave={() => setHoveredNode(null)}
                                >
                                {(browseModifier && (hoveredNode === row || cursor===row.prevPlaceholder)) &&
                                    <div className="placeholder" style={{top: row.prevPlaceholder.yPx, left: row.prevPlaceholder.xPx, minWidth: row.width, minHeight: nodeStyle.marginExpanded}}>
                                        <div onClick={() => {
                                            createSiblingNode(row, true)
                                        }} className={"circle " + (cursor===row.prevPlaceholder ? 'placeholderHighlighted' : '')}>
                                            <Plus  />
                                        </div>
                                    </div>
                                    }
                                    
                                    
                                    
                                    <div onClick={() => {
                                        if (isEditing) {
                                            updateNodeText(cursor, editText);
                                        } setCursor(row);
                                    }} 
                                    className={"cell " + (cursor===row ? 'selected ' : '') + (row.locked ? 'lockedCell' : (row.creator === username ? 'myCell' : 'yourCell'))} 
                                    style={{left: row.xPx, top: row.yPx, height: row.height, width: row.width }}
                                    >
                                        <div className="cellText" style={{position: 'relative', padding: nodeStyle.padding}}>{row.text}
                                        </div>
                                    </div>
                                
                                    {
                                    ((browseModifier&&hoveredNode === row)|| cursor===row.nextPlaceholder)  &&
                                    <div className={"placeholder" } style={{top: row.nextPlaceholder.yPx, left: row.nextPlaceholder.xPx, minWidth: row.width, minHeight: nodeStyle.marginExpanded}}>
                                        <div onClick={() => {
                                            createSiblingNode(row, false)
                                        }} className={"circle " + (cursor===row.nextPlaceholder ? 'placeholderHighlighted' : '')}>
                                            <Plus  />
                                        </div>
                                    </div>
                                    }

                                    {row.childPlaceholder &&
                                    <>
                                        {
                                        (browseModifier && (hoveredNode === row || cursor===row.childPlaceholder)) &&
                                        <div className={"placeholder" } style={{top: row.childPlaceholder.yPx, left: row.childPlaceholder.xPx, minWidth: nodeStyle.marginExpanded, minHeight: row.height}}>
                                            <div onClick={() => {
                                                createChildNode(row)
                                            }} className={"circle " + (cursor===row.childPlaceholder ? 'placeholderHighlighted' : '')}>
                                                <Plus  />
                                            </div>
                                        </div>
                                        }
                                    </>
                                    }




                                </div>
                                :
                                <div className="editContainer" style={{top: row.yPx, left: row.xPx, width: editContainerDimensions.width, height: editContainerDimensions.height}}>
                                    <input value={editText} ref={editRef} onChange={(e) => setEditText(e.target.value)} />
                                </div>
                            }
                            </Fragment>
                        )
                    })}
                    </>

                    <div className="stretcher" style={{top: totalDimensions.height, left: totalDimensions.width}}></div>
                </div>
    )
})

export default DocumentRender