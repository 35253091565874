import React, { createRef, useEffect, useState } from 'react';
import egdata from './data';
import egdata2 from './data2';
import egdata3 from './data3';
import ThreeForceGraph from 'three-forcegraph';
import renderToSprite from './renderToSprite';
import MindMapNode from './MindMapNode';
import * as THREE from 'three';
import colorsByLevel from './colorsByLevel';
import updateLinkPosition from './updateLinkPosition';
import { renderToStaticMarkup } from 'react-dom/server';





const nodeStyle = {
  indent: 150,
  minWidth: 50,
  minHeight: 18,
  marginCollapsed: 5,
  marginExpanded: 20,
  padding: 5,
  maxWidth: 250
}


var getDimensionsOfText = function(text, styles) {
  var isObjectJSON = function(obj) {
      return obj && typeof obj === 'object' && !Array.isArray(obj);
  };

  var element = document.createElement('div');
  if (isObjectJSON(styles)) {
      var styleKeys = Object.keys(styles);
      for (var i = 0, n = styleKeys.length; i < n; ++i) {
          element.style[styleKeys[i]] = styles[styleKeys[i]];
      }
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  var width = element.offsetWidth;
  var height = element.offsetHeight;
  document.body.removeChild(element);
  return {width, height};
};

const getDimensionsOfNode = (node) => {
  const {width, height} = getDimensionsOfText(node.text, {minWidth: nodeStyle.minWidth + 'px', minHeight: nodeStyle.minHeight + 'px', padding: nodeStyle.padding + "px", maxWidth: nodeStyle.maxWidth + "px"})
  return {width: width+5, height}
}


export default function Mindmap({data, cursor}) {
  const [camera, setCamera] = useState();
  const [graphData, setGraphData] = useState({
    nodes: [],
    links: []
  })
  const divRef = createRef();

  const render = async () => {

    console.log("STARTING TO RUN")

    if (divRef.current) {
      while(divRef.current.firstChild)
      divRef.current.removeChild(divRef.current.firstChild);
  }

    const graphDataLocal = {
      nodes: [],
      links: []
    }

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
    
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
    divRef.current.appendChild( renderer.domElement );

    let idCounter = 1;
    const startLevel = 0;

    console.log("WINDOW CREATED")

    const addNode = (node, level, parentId, parent) => {
      const id = idCounter.toString();
      const {width, height} = getDimensionsOfNode(node)
      graphDataLocal.nodes.push({id, level, name: node.text, val: 50, width, height})
      node.mindmapId = id;

      node.parent = parent;
      // console.log(width, height)
      if (parentId) {
        graphDataLocal.links.push({source: parentId, target: id, level: level-1})

        const idx = parent.children.indexOf(node);
        const next = parent.children[idx+1]
        if (next) node.nextNode = next;
        const prev = parent.children[idx-1]
        if (prev) node.prevNode = prev;
        
      }
      idCounter++
      if (node.children) {
        node.children.forEach((child) => {
          addNode(child, level+1, id, node)
        })
      }
    }

    addNode(data[0], startLevel)


    console.log("NODES FORMATTED")

    // graphData = egdata2

    graphDataLocal.nodes = await Promise.all(
      graphDataLocal.nodes.map((node) =>{
        return renderToSprite(<MindMapNode label={node.name?.replace?.("#", "")} level={node.level} />, node.name, {
          width: node.width,
          height: node.height
        }).then((sprite) => ({ ...node, sprite })).catch((e) => console.log(e))
      }
      )
      );

      console.log("GRAPH DATA LOADED")
    const graph = new ThreeForceGraph()
      .graphData(graphDataLocal);

      console.log("FORCE GRAPH CREATED")

    graph.nodeThreeObject(({ sprite }) => sprite);

    console.log("TEXTURES ASSIGNED")
    
    graph.numDimensions(2);

    graph.linkMaterial(
        ({ level }) => new THREE.MeshBasicMaterial({ color: colorsByLevel[level] || colorsByLevel[1] })
      );

      graph.linkPositionUpdate(updateLinkPosition);
      graph.linkWidth(1);

    // Setup scene
    scene.add(graph);
    scene.add(new THREE.AmbientLight(0xcccccc, Math.PI));

    // Setup camera
    camera.far = 10000;
    camera.aspect = window.innerWidth/window.innerHeight;
    camera.updateProjectionMatrix();
    camera.lookAt(graph.position);
    const zoom = 100
    camera.position.z = zoom;
    //camera.position.z = Math.cbrt(graphData.nodes.length) * 90;

    // Kick-off renderer
    (function animate() { // IIFE
      try {
        graph.tickFrame();
      }
      catch (e) {
        console.log(e)
      }

      if (camera.destSteps >= 1) {
        const dx = (camera.destX - camera.position.x)/camera.destSteps
        const dy = (camera.destY - camera.position.y)/camera.destSteps
        camera.position.set(camera.position.x + dx, camera.position.y + dy, zoom)
        camera.destSteps--;
      }
      else if (camera.destSteps === 1) {
        camera.position.set(camera.destX , camera.destY, zoom)
        camera.destX = undefined
        camera.destY = undefined
        camera.destSteps = undefined
      }

      if (camera.scrollX || camera.scrollY) {
        camera.position.set(camera.position.x + camera.scrollX, camera.position.y + camera.scrollY, zoom)
        camera.scrollX = 0;
        camera.scrollY = 0;
      }

      // Frame cycle
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    })();
    setGraphData(graphDataLocal)
    return camera
} 
  useEffect(() => {

     async function run() {
      if (data[0]) {
        console.log("ABOUT TO RUN")
        const c =  await render()
        setCamera(c);
      }
    }
    run();
    return () => {
        if (divRef.current) {
            while(divRef.current.firstChild)
            divRef.current.removeChild(divRef.current.firstChild);
        }
    }
    }, [data]);

    const scrollFunc =  (e) => {
      if (!camera.scrollX) camera.scrollX = +e.deltaX
      else camera.scrollX += e.deltaX

      if (!camera.scrollY) camera.scrollY = -e.deltaY
      else camera.scrollY -= e.deltaY
    }

    useEffect(() => {
      if (camera) {
        divRef.current.removeEventListener('mousewheel', scrollFunc)
        divRef.current.addEventListener('mousewheel', scrollFunc)
        
      }
    }, [camera])

    useEffect(() => {
      if (camera && cursor) {
        const graphDataEntry = graphData.nodes.find((n) => n.id === cursor.mindmapId)
        if (graphDataEntry && graphDataEntry.x) {
           camera.destX = graphDataEntry.x
           camera.destY = graphDataEntry.y
           camera.destSteps = 10
        }
      }
    }, [camera, cursor])
  return <div style={{overscrollBehaviorX: 'contain'}} ref={divRef} />;
}
