import { useState, useEffect, useContext } from "react"

import { Navigate } from "react-router-dom"

import { LoginContext } from "../LoginProvider"

import { GoogleLogin } from '@react-oauth/google';

// import FacebookLogin from 'react-facebook-login';

function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [errorText, setErrorText] = useState("")

    const {login, loginGoogle} = useContext(LoginContext)

    const handleLogin = async () => {
        setErrorText("")
        const result = login(username, password);
        if (result?.error) setErrorText(result.message)
    }
    const errorMessage = (error) => {
        console.log(error);
    };

    if (sessionStorage.getItem("token")) return <Navigate to="/dashboard" />

    return (
        <div className="center-screen">
            <div className="login-box">
                <h1 className="login-header">Login</h1>

                <div className="login-content">
                    <div style={{marginBottom: '50px'}}>
                    <label htmlFor="username">Username</label>
                    <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div style={{marginBottom: '50px'}}>
                    <label htmlFor="password" >Password</label>
                    <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>

                    <button onClick={handleLogin}>Submit</button>

                    <GoogleLogin onSuccess={loginGoogle} onError={errorMessage} />

                    {/* <FacebookLogin
                        appId="EAAQR9KgXVPsBO2qDPi7jsrWLH08jzi5xqVQDQdLQXcB6Qps3oSpJpgZCbypZCUKSpNlL3OZCron3MF0mAZCYOuAxXrnu08cnuVg7G4x8BgyBdxZBeUiRqwyPYDZCALgQWAQBEPlbCj9Tp2EAsYwzL9nyMM2DKmkZAEZCcss62VmL68vrolTACEYYaOmyUtoDPkZCI8wFwbX2DuMlZCAtRyVoyTdZCfm4WAzV8xL3fKc2oySEOlEmD0KLGLQhDcv37U8J2ZBZAlbKUu70W"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook} /> */}

                    <div style={{marginTop: '100px', color: 'red'}}>
                        {errorText}
                    </div>
   
                </div>
            </div>
        </div>
    )
}

export default Login