import { renderToStaticMarkup } from 'react-dom/server';

function loadImage(url) {
  const image = new window.Image();
  return new Promise((resolve) => {
    image.onload = () => resolve(image);
    image.onerror = (e) => console.log(e)
    image.crossOrigin = "anonymous"
    image.src = url;
  });
}



export default async function renderToCanvas(content, { width, height }) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const url = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <style type="text/css">
        <![CDATA[
          ${document.getElementById('styles').innerHTML}
        ]]>
      </style>
      <foreignObject width="${width}" height="${height}">
      ${renderToStaticMarkup(content)}
      </foreignObject>
      </svg>`;
  const image = await loadImage(url);
  ctx.drawImage(image, 0, 0);
  return canvas;
}


export function  renderToCanvas_draw(text, {width, height}) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");
  context.beginPath();
  context.roundRect(0, 0, width, height, [40]);
  context.fillStyle = "#99FFCC";
  context.fill();
  context.closePath();
  if (text) {
    context.font = "14px Arial, Helvetica, sans-serif";
    context.fillStyle = "black";
    const splitText = getLines(context, text, width)
    splitText.forEach((line, i) => {
      context.fillText(line, 10, 20 + i*15);
    })
  }
  return canvas
}

function getLines(ctx, text, maxWidth) {
  var words = text.split(" ");
  var lines = [];
  var currentLine = words[0];

  for (var i = 1; i < words.length; i++) {
      var word = words[i];
      var width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
          currentLine += " " + word;
      } else {
          lines.push(currentLine);
          currentLine = word;
      }
  }
  lines.push(currentLine);
  return lines;
}