import { Outlet } from "react-router-dom"
import { createContext, useState, useRef, useEffect, useMemo, useContext } from 'react'
import { ApiLogin, APILoginGoogle } from "./api"

import { useNavigate} from "react-router-dom"

import { WebsocketsContext } from "./WebsocketsProvider";

export const LoginContext = createContext(null)


const LoginProvider = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const {socket, socketLoaded} = useContext(WebsocketsContext)
    const [un, setUn] = useState(null);
    const [connected, setConnected] = useState(false);
    const [documentId, setDocumentId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (socketLoaded) {
            socket.removeAllListeners("loggedIn");
            socket.on("loggedIn", () => {
                navigate("/dashboard")
                setLoggedIn(true)
            })
            socket.removeAllListeners("loggedOut");
            socket.on("loggedOut", () => {
                navigate("/")
                setLoggedIn(false)
            })
            socket.removeAllListeners("sessionLoaded");
            socket.on("sessionLoaded", () => {
                setLoggedIn(true);
            })
            socket.removeAllListeners("connect");
            socket.on("connect", () => {
                setConnected(true);
                loadSession();
            })
            socket.removeAllListeners("disconnect");
            socket.on("disconnect", () => {
                setConnected(false);
                loadSession();
            })
        }
    }, [socketLoaded])

    const loadSession = async() => {
        if (sessionStorage.getItem('token')) {
            socket.emit("loadSession", {token: sessionStorage.getItem("token").split(" ")[1], documentId})
        }
    }

    useEffect(() => {
        if (socketLoaded) {
            setUn(sessionStorage.getItem("username"))
        }
    }, [socketLoaded])


    const login = async (username, password) => {
        const result = await ApiLogin({username, password})
        if (result.status === "fail") {
            return {error: true, message: result.message}
        }
        if (result.status === "success") {
            sessionStorage.setItem("token", "BEARER "+result.data.accessToken)
            sessionStorage.setItem("username", username)
            setUn(username)
            socket.emit("login", {token: result.data.accessToken})
        }
    }

    const loginGoogle = async (response) => {
        const result = await APILoginGoogle({googleToken: response.credential})
        if (result.status === "fail") {
            return {error: true, message: result.message}
        }
        if (result.status === "success") {
            sessionStorage.setItem("token", "BEARER "+result.data.accessToken)
            sessionStorage.setItem("username", result.data.username)
            setUn(result.data.username)
            socket.emit("login", {token: result.data.accessToken})
        }
    }

    const logout = () => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("username");
            socket.emit("logOut")
    }

    const data = {
        loggedIn,
        login,
        loginGoogle,
        logout,
        setDocumentId,
        connected,
        username: un
    }
    
    return <LoginContext.Provider value={data}><Outlet /></LoginContext.Provider>
}

export default LoginProvider